<template>
  <div class="pa-3 d-flex flex-column flex-grow-1">
    <div class="d-flex align-center mx-2 gap">
      <v-select
        v-if="dataSources"
        :label="$t('t.DestinationDataSource')"
        v-model="computedDataSourceId"
        :items="dataSources"
        item-text="name"
        item-value="id"
        hide-details
        class="mt-4 mr-4 pa-0 data-source-select flex-shrink-0"
      />
      <v-progress-linear
        class="mr-2 border-radius text-no-wrap"
        :value="sessionSizePercent"
        :color="progressColor"
        height="25"
        :class="{ 'manual-disabled': !dataSourceId }"
      >
        <span>{{computeSessionSizeMB}} / {{computeSessionMaxSizeMB}} {{$t('t.MB')}}</span>
        <span class="ml-2 grey--text caption">({{$t('t.MaxFilesCapacity')}})</span>
      </v-progress-linear>
      <v-spacer />
      <v-tooltip
        top
        v-if="dataSourceId"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="mr-4"
            v-on="on"
            v-bind="attrs"
            @click="showManualImportDialog = true"
            :disabled="!computedCanDoImport"
          >{{$t('t.LaunchManualImport')}}</v-btn>
        </template>
        <span>{{$t('t.LaunchManualImportTooltip')}}</span>
      </v-tooltip>

      <v-tooltip
        top
        v-if="!dataSourceId"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="mr-4"
            v-on="on"
            v-bind="attrs"
            :disabled="!computedCanDoImport"
            @click="showImportDialog = true"
          >{{$t('t.LaunchImport')}}</v-btn>
        </template>
        <span>{{$t('t.LaunchImportTooltip')}}</span>
      </v-tooltip>
    </div>
    <grid
      class="py-4 px-2 overflow-y-auto"
      item-min-width="49.4%"
    >
      <drop-file
        v-for="(entity, entityIdx) in entities"
        :key="entityIdx"
        :readonly="!dataSourceId"
        @add-files="addFiles(entity, $event)"
      >
        <template v-slot="{fileDropListener}">
          <v-card
            v-on="fileDropListener"
            class="border-radius ma-0"
            :disabled="!dataSourceId"
          >
            <v-card-title class="pb-0 no-wrap align-baseline">
              <span>{{$t('t.DropYourDocumentFileHere1')}} </span>
              <v-icon
                class="mx-2"
                small
              >{{$icon(`i.${toPascalCase(entity.ty)}`)}}</v-icon>
              <span class="primary--text mr-2"> {{$t(`t.${toPascalCase(entity.ty)}`).toLowerCase()}} </span>
              <span>{{$t('t.DropYourDocumentFileHere2')}}</span>
              <v-btn
                fab
                x-small
                class="mx-2"
                :disabled="!dataSourceId"
                @click="$refs.input[entityIdx].$el.querySelector('button').click()"
              >
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on">{{$icon('i.UploadFile')}}</v-icon>
                  </template>
                  {{$t('t.AddFile')}}
                </v-tooltip>
              </v-btn>
              <v-file-input
                :clearable="true"
                :hide-input="true"
                multiple
                v-on:change="addFiles(entity, $event)"
                style="display: none"
                ref="input"
                v-model="currentFiles"
                :disabled="!dataSourceId"
              />
              <v-spacer />
              <v-tooltip
                top
                v-if="requiredFileCount(entity) > 0"
              >
                <template v-slot:activator="{ on }">
                  <span
                    v-on="on"
                    class="text-h6"
                    :class="[requiredFileCount(entity) > 0 && nbrFile(entity) !== requiredFileCount(entity) ? 'error--text' : 'primary--text']"
                  >
                    {{nbrFile(entity)}} / {{requiredFileCount(entity)}}
                  </span>
                </template>
                <span>{{$t('t.ImportConditionFileNumber')}}</span>
              </v-tooltip>
              <span
                v-else
                class="primary--text"
              >{{nbrFile(entity)}}</span>
              <v-btn
                class="ml-3"
                fab
                x-small
                color="error"
                @click="removeAllFiles(entity)"
                :disabled="!dataSourceId || (entity.files && entity.files.length === 0)"
              >
                <icon-tooltip
                  :icon-name="'i.Delete'"
                  :tooltip-text="$t('t.RemoveAll')"
                />
              </v-btn>
            </v-card-title>
            <v-card-text>
              <v-chip-group
                column
                class="entity-box"
              >
                <v-chip
                  v-for="(file, fileIdx) in entity.files"
                  :key="fileIdx"
                  close
                  small
                  @click:close="removeFile(entity, fileIdx)"
                  class="file-chip"
                >
                  <span class="filename">{{file.name}}</span>
                </v-chip>
              </v-chip-group>
            </v-card-text>
          </v-card>
        </template>
      </drop-file>
    </grid>
    <confirmation ref="duplicates">
      <confirmation-content
        :title="$t('t.DuplicateFilenameFound')"
        :message="duplicateFilesMessage"
        :list='duplicateFiles'
        displayFormat='list'
        :consent='false'
      />
    </confirmation>
    <confirmation ref="filesToLarge">
      <confirmation-content
        :title="$t('t.FilesTooLarge')"
        :message="filesTooLargeMessage"
        :list="largeFiles"
        displayFormat='list'
        :consent='false'
      />
    </confirmation>
    <v-dialog
      width="500"
      v-model="showManualImportDialog"
      @click:outside="showManualImportDialog = false"
    >
      <v-card>
        <v-card-title>{{$t('t.LaunchManualImport')}}</v-card-title>
        <v-card-text class="text-format">
          <v-checkbox
            :label="$t('t.LaunchImportRelatedEngines')"
            v-model="launchEngines"
            class="mt-4 pa-0 nowrap"
            hide-details
          />
          <v-divider class="my-4" />
          <span>{{computedManualImportRecap}}</span>

        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn @click="launchManualImport">{{$t('t.Yes')}}</v-btn>
          <v-btn
            color="primary"
            @click="showManualImportDialog = false"
          >{{$t('t.No')}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      width="500"
      v-model="showImportDialog"
      @click:outside="showImportDialog = false"
    >
      <v-card>
        <v-card-title>{{$t('t.LaunchImport')}}</v-card-title>
        <v-card-text class="text-format">
          <span>{{$t('t.LaunchImportConfirmation')}}</span>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn @click="launchImport">{{$t('t.Yes')}}</v-btn>
          <v-btn
            color="primary"
            @click="showImportDialog = false"
          >{{$t('t.No')}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import { v4 as uuid } from 'uuid'
import { GlobalSearch } from '@/pages/search/controllers'

export default {
  components: {
    Confirmation: () => import('@/components/confirmation.vue'),
    ConfirmationContent: () => import('@/components/confirmation-content.vue'),
    Grid: () => import('@/components/grid'),
    DropFile: () => import('@/components/drop-file'),
    IconTooltip: () => import('@/components/icon-tooltip')
  },
  data () {
    return {
      showImportDialog: false,
      showManualImportDialog: false,
      currentFiles: [],
      entities: [],
      allowDuplicates: false,
      dataSourceId: null,
      dataSources: [],
      dropTable: true,
      duplicateFiles: undefined,
      duplicateFilesMessage: undefined,
      largeFiles: [],
      importRecap: undefined,
      intervalId: undefined,
      filesTooLargeMessage: undefined,
      launchEngines: true,
      required: [v => (!!v && !this.lodash.isEqual(v, {}) && !this.lodash.isEqual(v, { id: null })) || this.$t('t.IsRequired')],
      sessionMaxSizeMB: 0,
      sessionSizeMB: 0,
      sessionSize: 0
    }
  },
  watch: {
  },
  computed: {
    computedManualImportRecap () {
      const r = {
        dataSource: this.dataSources.find(ds => ds.id === this.dataSourceId)?.name
      }

      this.entities.forEach(entity => {
        r[this.lodash.camelCase(entity.ty)] = this.nbrFilePerEntity(entity)
      })

      return this.$t('t.ManualImportRecap', r)
    },
    computedCanDoImport () {
      if (!this.dataSourceId) { return true }

      const ds = this.dataSources?.find(ds => ds.id === this.dataSourceId)

      return ds && this.entities.every(entity => {
        const targetCount = ds.format?.find(f => f.entity === entity.ty)?.fileCount ?? 0
        return targetCount === 0 || targetCount === entity.files.length
      }) && this.entities.reduce((acc, e) => acc + e.files.length, 0) > 0 && this.sessionSize !== 0
    },
    computedDataSourceId: {
      get () { return this.dataSourceId },
      set (v) {
        if (this.dataSourceId !== v) {
          this.dataSourceId = v
          this.updateTime()
        }
      }
    },
    currentUserId () {
      return this.$store.getters.currentUser.id
    },
    computeSessionSizeMB () {
      return this.sessionSize !== 0 ? (this.sessionSize / 1024 / 1024).toFixed(4) : 0
    },
    computeSessionMaxSizeMB () {
      return this.sessionMaxSizeMB
    },
    progressColor () {
      if (this.sessionSizePercent > 85) {
        return 'error'
      } else if (this.sessionSizePercent > 50) {
        return 'warning'
      }
      return 'success'
    },
    sessionSizePercent () {
      if (this.sessionMaxSizeMB) {
        return this.sessionSizeMB / this.sessionMaxSizeMB * 100
      } else {
        return 0
      }
    }
  },
  props: {
    tab: Object
  },
  methods: {
    async addFiles (entity, files) {
      const commonFilename = files.filter(file => entity.files.some(f => file.name === f.name))
      this.duplicateFiles = commonFilename.map(f => f.name)
      this.duplicateFilesMessage = this.$tc('t.DuplicateFilenameFoundText', this.duplicateFiles.length)

      if (commonFilename.length > 0) {
        await this.$refs.duplicates.open()
      } else {
        await this.pushFiles(files, entity)
      }
      this.duplicateFiles = null
    },
    async pushFiles (files, entity) {
      for (const file of files) {
        file.id = uuid()
        const formData = new FormData()
        formData.append('id', file.id)
        formData.append('name', file.name)
        formData.append('size', file.size)
        formData.append('file', file)

        await this.$http().post(`/core/import/manual/add-file/${entity.ty}`, formData).then(r => {
          this.sessionSize = r.data.sessionSize
          this.sessionSizeMB = r.data.sessionSizeMB
          this.sessionMaxSizeMB = r.data.sessionMaxSizeMB
          entity.files.push(file)
        }).catch(e => {
          this.largeFiles.push(file.name)
        })
      }

      if (this.largeFiles.length > 0) {
        this.filesTooLargeMessage = this.$tc('t.FilesTooLargeMessage', this.largeFiles.length)
        await this.$refs.filesToLarge.open()
        this.largeFiles = []
      }
    },
    deleteChip (entityIdx, fileIdx) {
      this.removeFile(entityIdx, fileIdx)
    },
    async removeAllFiles (entity) {
      await this.$http().post(`/core/import/manual/delete-all-files/${entity.ty}`).then(r => {
        entity.files = []
        this.largeFiles = []
        this.sessionSize = r.data.sessionSize
        this.sessionSizeMB = r.data.sessionSizeMB
        this.sessionMaxSizeMB = r.data.sessionMaxSizeMB
      })
    },
    async removeFile (entity, fileIdx) {
      const v = entity.files.splice(fileIdx, 1)[0]
      await this.$http().post(
        `/core/import/manual/delete-file/${entity.ty}`,
        {
          id: v.id,
          name: v.name,
          size: v.size
        }
      ).then(r => {
        this.sessionSize = r.data.sessionSize
        this.sessionSizeMB = r.data.sessionSizeMB
        this.sessionMaxSizeMB = r.data.sessionMaxSizeMB
        this.currentFiles = entity.files
      })
    },
    nbrFile (entity) {
      return this.entities.find(e => e.ty === entity.ty).files.length
    },
    requiredFileCount (entity) {
      if (this.dataSourceId) {
        const ds = this.dataSources?.find(ds => ds.id === this.dataSourceId)
        const f = ds?.format?.find(f => f.entity === entity.ty)
        return f?.fileCount ?? 0
      } else {
        return 0
      }
    },
    nbrFilePerEntity (entity) {
      return this.entities.find(e => e.ty === entity.ty).files.length
    },
    async updateTime () {
      await this.$http().post('/core/import/manual/update', { dataSourceId: this.dataSourceId })
    },
    async launchImport () {
      await this.$http().post('/core/import/engine/run')
        .then(async () => {
          this.$store.dispatch('showSuccessSnackbar', this.successMessage ?? this.$t('t.Snackbar.TaskLaunched'))
          this.open('runs')
        })
        .catch(e => {
          this.$store.dispatch('showErrorSnackbar', this.$t('t.CriticalError'))
        })
    },
    async launchManualImport () {
      await this.$http().post('/core/import/manual/run', {
        dataSourceId: this.dataSourceId,
        postImportEnginesLaunch: this.launchEngines
      })
        .then(async () => {
          this.$store.dispatch('showSuccessSnackbar', this.successMessage ?? this.$t('t.Snackbar.TaskLaunched'))
          this.open('runs')
        })
        .catch(e => {
          this.$store.dispatch('showErrorSnackbar', this.$t('t.CriticalError'))
        })
    },
    open (type) {
      this.$router.push('/search')
        .catch(e => {
          if (e.name === 'NavigationDuplicated') { throw e }
        })
        .then(() => GlobalSearch.activeSearch.setDocumentType(type).execute(true))
    },
    boolTrad (val) {
      if (val) {
        return this.$t('t.True')
      } else {
        return this.$t('t.False')
      }
    }
  },
  async beforeMount () {
    const [
      dataSourcesReq,
      entitiesReq
    ] = await Promise.all([
      this.$http().get('/core/import/manual/data-sources'),
      this.$http().get('/core/import/manual/session')
    ])

    this.dataSources = dataSourcesReq.data.dataSources
    this.computedDataSourceId = this.dataSources[0].id
    this.dataSourceId = entitiesReq.data.dataSourceId
    this.entities = entitiesReq.data.entities
    this.sessionSize = entitiesReq.data.sessionSize
    this.sessionSizeMB = entitiesReq.data.sessionSizeMB
    this.sessionMaxSizeMB = entitiesReq.data.sessionMaxSizeMB

    this.intervalId = setInterval(this.updateTime, 25000)
  },
  beforeDestroy () {
    clearInterval(this.intervalId)
    this.intervalId = undefined
  }
}
</script>

<style lang="stylus" scoped>
.no-wrap
  white-space-collapse preserve-spaces
  text-wrap-mode nowrap

.text-format
  white-space pre-wrap !important

@css {
  .entity-box {
    height: max(100px, 12vh);
    overscroll-behavior: none;
  }
}

.file-chip
  max-width 30%

.filename
  white-space nowrap
  text-overflow ellipsis
  overflow hidden

.data-source-select
  flex-basis 200px

.manual-disabled
  opacity 0.5

>>> .v-slide-group__wrapper
  overflow-y auto

>>> .v-slide-group__content
  align-content baseline
</style>
